import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@mui/material';

const AddSpecialTourType = () => {
  const [tourType, setTourType] = useState('');

  const [heading, setHeading] = useState('');
  const [descTxt, setDescTxt] = useState('');
  const [bannerType, setBannerType] = useState('image');
  const [bannerSrc, setBannerSrc] = useState(null);
  const [bannerAlt, setBannerAlt] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerDescription, setBannerDescription] = useState('');

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setBannerSrc(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('tour_type', tourType);

    formData.append('heading', heading);
    formData.append('desc_txt', descTxt);
    formData.append('banner_type', bannerType);
    formData.append('banner_src', bannerSrc);
    formData.append('banner_alt', bannerAlt);
    formData.append('banner_title', bannerTitle);
    formData.append('banner_description', bannerDescription);

    try {
      await axios.post('https://aaryanholidays.in/backend/special-tour-types', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate('/manage-special-tour-type');
    } catch (error) {
      console.error('There was an error adding the tour type!', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Add Special Tour Location</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Tour Type"
          fullWidth
          margin="normal"
          value={tourType}
          onChange={(e) => setTourType(e.target.value)}
        />
        
        <TextField
          label="Heading"
          fullWidth
          margin="normal"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
        />
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          value={descTxt}
          onChange={(e) => setDescTxt(e.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Banner Type</FormLabel>
          <RadioGroup row value={bannerType} onChange={(e) => setBannerType(e.target.value)}>
            <FormControlLabel value="image" control={<Radio />} label="Image" />
            <FormControlLabel value="video" control={<Radio />} label="Video" />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel>Upload Banner</FormLabel>
          <input
            type="file"
            onChange={handleFileChange}
            accept={bannerType === 'image' ? 'image/*' : 'video/*'}
          />
          {bannerSrc && bannerType === 'image' && (
            <img
              src={URL.createObjectURL(bannerSrc)}
              alt="Banner Preview"
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {bannerSrc && bannerType === 'video' && (
            <video
              src={URL.createObjectURL(bannerSrc)}
              controls
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
        </FormControl>
        <TextField
          label="Banner Alt"
          fullWidth
          margin="normal"
          value={bannerAlt}
          onChange={(e) => setBannerAlt(e.target.value)}
        />
        <TextField
          label="Banner Title"
          fullWidth
          margin="normal"
          value={bannerTitle}
          onChange={(e) => setBannerTitle(e.target.value)}
        />
        <TextField
          label="Banner Description"
          fullWidth
          margin="normal"
          value={bannerDescription}
          onChange={(e) => setBannerDescription(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">Save</Button>
      </form>
    </Box>
  );
};

export default AddSpecialTourType;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const VideoThumbnail = styled('video')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const ManageGallery = () => {
  const { destinations_id } = useParams();
  const [galleryItems, setGalleryItems] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedGalleryItemId, setSelectedGalleryItemId] = useState(null);

  useEffect(() => {
    axios
      .get(`https://aaryanholidays.in/backend/gallery/${destinations_id}`)
      .then((response) => setGalleryItems(response.data))
      .catch((error) => console.error('There was an error fetching the gallery items!', error));
  }, [destinations_id]);

  const handleDelete = (id) => {
    axios
      .delete(`https://aaryanholidays.in/backend/gallery/${id}`)
      .then((response) => setGalleryItems(galleryItems.filter((item) => item.id !== id)))
      .catch((error) => console.error('There was an error deleting the gallery item!', error));
    handleCloseConfirmDialog();
  };

  const handleOpenConfirmDialog = (id) => {
    setSelectedGalleryItemId(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSelectedGalleryItemId(null);
    setOpenConfirmDialog(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Manage Gallery
      </Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Button component={Link} to={`/manage-gallery/add/${destinations_id}`} variant="contained" color="primary">
          Add New
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
             
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Alt</StyledTableCell>
              <StyledTableCell>Image Order</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {galleryItems.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
               
                <TableCell>
                  {item.type === 'img' ? (
                    <Thumbnail src={`https://aaryanholidays.in//express/uploads/${item.src}`} alt={item.alt} />
                  ) : (
                    <VideoThumbnail src={`https://aaryanholidays.in//express/uploads/${item.src}`} controls />
                  )}
                </TableCell>
                <TableCell>{item.alt}</TableCell>
                <TableCell>{item.image_order}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton component={Link} to={`/manage-gallery/edit/${item.id}`}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleOpenConfirmDialog(item.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this gallery item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(selectedGalleryItemId)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageGallery;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton,Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const ManagePartners = () => {
    const [partners, setPartners] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://aaryanholidays.in/backend/partners')
            .then(response => setPartners(response.data))
            .catch(error => console.error('Error fetching partners:', error));
    }, []);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this partner?')) {
            axios.delete(`https://aaryanholidays.in/backend/partners/${id}`)
                .then(() => setPartners(partners.filter(partner => partner.id !== id)))
                .catch(error => console.error('Error deleting partner:', error));
        }
    };

    return (
        <Paper style={{ padding: '20px' }}>
             <Typography variant="h4" gutterBottom>
        Manage Partner
      </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary" component={Link} to="/add-partner">
                        Add New Partner
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Brief</TableCell>
                            <TableCell>Promotion Order</TableCell>
                            <TableCell>Video</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partners.map((partner) => (
                            <TableRow key={partner.id}>
                                <TableCell>{partner.name}</TableCell>
                                <TableCell>{partner.brief}</TableCell>
                                <TableCell>{partner.promotion_order}</TableCell>
                                <TableCell>
                                    <a href={`https://www.youtube.com/embed/${partner.video_link}?&controls=1&mute=0&disablekb=1`} target="_blank" rel="noopener noreferrer">
                                        <img src={`https://img.youtube.com/vi/${partner.video_link}/hqdefault.jpg`} alt={partner.name} width="100" />
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => navigate(`/edit-partner/${partner.id}`)} color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(partner.id)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ManagePartners;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField, Typography, RadioGroup, FormControlLabel, Radio, Checkbox, FormControl, FormLabel, Snackbar, Alert } from '@mui/material';

const AddDestination = () => {
  const { tourTypeId } = useParams();
  const [locations, setLocations] = useState([]);
  const [discovery, setDiscovery] = useState([]);
  const [discoverydata, setDiscoveryData] = useState([]);
  const [location, setLocation] = useState([]);
  const [thumbnailHeading, setThumbnailHeading] = useState('');
  const [thumbnailDesc, setThumbnailDesc] = useState('');
  const [besttimetovisittext, setBesttimetovisittext] = useState('');
  const [map, setMap] = useState('');
  const [type, setType] = useState('image');
  const [src, setSrc] = useState(null);
  const [alt, setAlt] = useState('');
  const [tourDays, setTourDays] = useState('');
  const [flights, setFlights] = useState('');
  const [hotels, setHotels] = useState('');
  const [transfers, setTransfers] = useState('');
  const [activities, setActivities] = useState('');
  const [uploadBrochures, setUploadBrochures] = useState(null);
  const [bodyHeadingTxt, setBodyHeadingTxt] = useState('');
  const [bodyTxt, setBodyTxt] = useState('');
  const [price, setPrice] = useState('');
  const [destinationOrder, setDestinationOrder] = useState('');
  const [isSpecialized, setIsSpecialized] = useState(false);
  const [promote, setPromote] = useState(false);
  const [promoteOrder, setPromoteOrder] = useState('');
  const [isActiveId, setIsActiveId] = useState(false);
  const [bannerType, setBannerType] = useState('image');
  const [bannerSrc, setBannerSrc] = useState(null);
  const [bannerAlt, setBannerAlt] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerDescription, setBannerDescription] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [keyword, setKeyword] = useState(''); // Updated state for single keyword
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://aaryanholidays.in/backend/locations')
      .then(response => setLocations(response.data))
      .catch(error => console.error('There was an error fetching the locations!', error));
  }, []);
  useEffect(() => {
    axios.get('https://aaryanholidays.in/backend/discovery')
      .then(response => setDiscoveryData(response.data))
      .catch(error => console.error('There was an error fetching the locations!', error));
  }, []);

  const handleFileChange = (e) => {
    setSrc(e.target.files[0]);
  };

  const handleBannerFileChange = (e) => {
    setBannerSrc(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


     // Validation: Check if required fields are missing
  if (!thumbnailHeading || !src ) {
    setSnackbarMessage('Please fill out all required fields: Package Name, Thumbnail');
    setSnackbarSeverity('error');
    setOpenSnackbar(true);
    return; // Stop submission if any of the fields are missing
  }
    // Create the tour_features string
    const tourFeatures = `${flights} Flights, ${hotels} Hotels, ${transfers} Transfers, ${activities} Activities`;

    const formData = new FormData();
    formData.append('location', location.join(', '));
    formData.append('discovery', discovery.join(', '));
    formData.append('thumbnail_heading', thumbnailHeading);
    formData.append('thumbnail_desc', thumbnailDesc);
    formData.append('type', type);
    formData.append('src', src);
    formData.append('alt', alt);
    formData.append('tour_days', tourDays);
    formData.append('tour_features', tourFeatures);
    formData.append('upload_brochures', uploadBrochures);
    formData.append('body_heading_txt', bodyHeadingTxt);
    formData.append('body_txt', bodyTxt);
    formData.append('price', price);
    formData.append('destination_order', destinationOrder);
    formData.append('is_specialized', isSpecialized ? 1 : 0);
    formData.append('promote', promote ? 1 : 0);
    formData.append('promote_order', promoteOrder);
    formData.append('is_active_id', isActiveId ? 1 : 0);
    formData.append('banner_type', 'image');
    formData.append('banner_src', bannerSrc);
    formData.append('banner_alt', bannerAlt);
    formData.append('banner_title', bannerTitle);
    formData.append('banner_description', bannerDescription);
    formData.append('tour_type_id', tourTypeId);
    formData.append('keyword', keyword); // Updated field for single keyword
    formData.append('map', map); // Updated field for single keyword
    formData.append('best_time_text', besttimetovisittext); // Updated field for single keyword
    

    try {
      await axios.post('https://aaryanholidays.in/backend/destinations', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSnackbarMessage('Destination added successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => navigate(`/manage-destination/${tourTypeId}`), 2000);
    } catch (error) {
      setSnackbarMessage('There was an error adding the destination!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.error('There was an error adding the destination!', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Add New Destination</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend" >Best Time To Visit</FormLabel>
          <Box  flexWrap="wrap">
            {locations.map(loc => (
              <FormControlLabel
                key={loc.id}
                control={
                  <Checkbox
                    checked={location.includes(loc.location)}
                    onChange={(e) => {
                      const newLocation = [...location];
                      if (e.target.checked) {
                        newLocation.push(loc.location);
                      } else {
                        const index = newLocation.indexOf(loc.location);
                        newLocation.splice(index, 1);
                      }
                      setLocation(newLocation);
                    }}
                    name={loc.location}
                  />
                }
                label={loc.location}
              />
            ))}
          </Box>
        </FormControl>
        <TextField
          label="Text For Best Time To Visit"
          fullWidth
          margin="normal"
          value={besttimetovisittext}
          onChange={(e) => setBesttimetovisittext(e.target.value)}
        />
        <TextField
          label="Map Link"
          fullWidth
          margin="normal"
          value={map}
          onChange={(e) => setMap(e.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend" >Discovery Tag</FormLabel>
          <Box  flexWrap="wrap">
             {discoverydata.map(dis => (
              <FormControlLabel
                key={dis.id}
                control={
                  <Checkbox
                    checked={discovery.includes(dis.discovery)}
                    onChange={(e) => {
                      const neDiscovery = [...discovery];
                      if (e.target.checked) {
                        neDiscovery.push(dis.discovery);
                      } else {
                        const index = neDiscovery.indexOf(dis.discovery);
                        neDiscovery.splice(index, 1);
                      }
                      setDiscovery(neDiscovery);
                    }}
                    name={dis.discovery}
                  />
                }
                label={dis.discovery}
              />
            ))} 

{/* {discovery.map(dis => (
  <FormControlLabel
    key={dis.id}
    control={
      <Checkbox
        checked={discovery.includes(dis.discovery)}
        onChange={(e) => {
          setDiscovery((prevDiscovery) => {
            if (e.target.checked) {
              return [...prevDiscovery, dis.discovery];
            } else {
              return prevDiscovery.filter(item => item !== dis.discovery);
            }
          });
        }}
        name={dis.discovery}
      />
    }
    label={dis.discovery}
  />
))} */}
          </Box>
        </FormControl>
        <TextField
          label="Package Name"
          fullWidth
          margin="normal"
          value={thumbnailHeading}
          onChange={(e) => setThumbnailHeading(e.target.value)}
        />
        <TextField
          label="Package Description for Thumbnail"
          fullWidth
          margin="normal"
          value={thumbnailDesc}
          onChange={(e) => setThumbnailDesc(e.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Thumbnail Type</FormLabel>
          <RadioGroup row value={type} onChange={(e) => setType(e.target.value)}>
            <FormControlLabel value="image" control={<Radio />} label="Image" />
            <FormControlLabel value="video" control={<Radio />} label="Video" />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Thumbnail Alt"
          fullWidth
          margin="normal"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
        />
        <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Thumbnail
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {src && (
            type === 'image' ? (
              <img src={URL.createObjectURL(src)} alt="Thumbnail Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
            ) : (
              <video src={URL.createObjectURL(src)} controls style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
            )
          )}
        </Box>
     
        <TextField
          label="Tour Days"
          fullWidth
          margin="normal"
          value={tourDays}
          onChange={(e) => setTourDays(e.target.value)}
        />
         <TextField
          label="Banner Alt"
          fullWidth
          margin="normal"
          value={bannerAlt}
          onChange={(e) => setBannerAlt(e.target.value)}
        />
        <TextField
          label="Banner Title"
          fullWidth
          margin="normal"
          value={bannerTitle}
          onChange={(e) => setBannerTitle(e.target.value)}
        />
        <TextField
          label="Banner Description"
          fullWidth
          margin="normal"
          multiline
          rows={2}
          value={bannerDescription}
          onChange={(e) => setBannerDescription(e.target.value)}
        />
        {/*
           <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Banner
            <input type="file" hidden onChange={handleBannerFileChange}  />
          </Button>
          {bannerSrc && (
            bannerType === 'image' ? (
              <img src={URL.createObjectURL(bannerSrc)} alt="Banner Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
            ) : (
              <video src={URL.createObjectURL(bannerSrc)} controls style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
            )
          )}
        </Box>
        */}
        <TextField
          label="Price"
          fullWidth
          margin="normal"
          multiline
          rows={2}
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <Box display="flex" flexDirection="row" justifyContent="space-between" margin="normal">
          <TextField
            label="Flights"
            margin="normal"
            value={flights}
            onChange={(e) => setFlights(e.target.value)}
          />
          <TextField
            label="Hotels"
            margin="normal"
            value={hotels}
            onChange={(e) => setHotels(e.target.value)}
          />
          <TextField
            label="Transfers"
            margin="normal"
            value={transfers}
            onChange={(e) => setTransfers(e.target.value)}
          />
          <TextField
            label="Activities"
            margin="normal"
            value={activities}
            onChange={(e) => setActivities(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Package Brochure
            <input type="file" hidden onChange={(e) => setUploadBrochures(e.target.files[0])} />
          </Button>
          {uploadBrochures && (
            <a href={URL.createObjectURL(uploadBrochures)} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px' }}>View Brochure</a>
          )}
        </Box>
        <TextField
          label="Heading"
          fullWidth
          margin="normal"
          value={bodyHeadingTxt}
          onChange={(e) => setBodyHeadingTxt(e.target.value)}
        />
        <TextField
          label="Detail"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={bodyTxt}
          onChange={(e) => setBodyTxt(e.target.value)}
        />
        
        <TextField
          label="Appear Order"
          fullWidth
          margin="normal"
          value={destinationOrder}
          onChange={(e) => setDestinationOrder(e.target.value)}
        />
        
        <FormControlLabel
          control={<Checkbox checked={promote} onChange={(e) => setPromote(e.target.checked)} />}
          label="Promote to Home Page"
        />
        <FormControlLabel
          control={<Checkbox checked={isSpecialized} onChange={(e) => setIsSpecialized(e.target.checked)} />}
          label="Marked As specialize Package"
        />
        <TextField
          label="Promote Order"
          fullWidth
          margin="normal"
          value={promoteOrder}
          onChange={(e) => setPromoteOrder(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={isActiveId} onChange={(e) => setIsActiveId(e.target.checked)} />}
          label="Active"
        />
       
        <TextField
          label="Keyword"
          fullWidth
          margin="normal"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />

       
        <Button type="submit" variant="contained" color="primary" fullWidth>Add Destination</Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddDestination;

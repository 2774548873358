import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert
} from '@mui/material';

const EditGallery = () => {
  const { id } = useParams();
  const [destinationsId, setDestinationsId] = useState('');
  const [type, setType] = useState('img');
  const [src, setSrc] = useState(null);
  const [currentSrc, setCurrentSrc] = useState('');
  const [srcPreview, setSrcPreview] = useState(null);
  const [alt, setAlt] = useState('');
  const [imageOrder, setImageOrder] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://aaryanholidays.in/backend/gallery/item/${id}`)
      .then(response => {
        const galleryItem = response.data;
        setDestinationsId(galleryItem.destinations_id);
        setType(galleryItem.type);
        setCurrentSrc(galleryItem.src);
        setAlt(galleryItem.alt);
        setImageOrder(galleryItem.image_order);
      })
      .catch(error => console.error('There was an error fetching the gallery item!', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('destinations_id', destinationsId);
    formData.append('type', 'img');
    if (src) {
      formData.append('src', src);
    }
    formData.append('alt', alt);
    formData.append('image_order', imageOrder);

    axios.put(`https://aaryanholidays.in/backend/gallery/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setSnackbarMessage('Gallery item updated successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate(`/manage-destination/gallery/${response.data.destinations_id}`);
      })
      .catch(error => {
        setSnackbarMessage('There was an error updating the gallery item.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('There was an error updating the gallery item!', error);
      });
  };

  const handleSrcChange = (e) => {
    const file = e.target.files[0];
    setSrc(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setSrcPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Edit Gallery Item</Typography>
      <form onSubmit={handleSubmit}>
        {/* <RadioGroup row value={type} onChange={(e) => setType(e.target.value)}>
          <FormControlLabel value="img" control={<Radio />} label="Image" />
          <FormControlLabel value="video" control={<Radio />} label="Video" />
        </RadioGroup> */}
        <input
          accept={type === 'img' ? 'image/*' : 'video/*'}
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleSrcChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span" sx={{ mt: 2 }}>
            Choose File
          </Button>
        </label>
        <Box display="flex" mt={2}>
          {currentSrc && (
            <Box mr={2}>
              <Typography variant="subtitle1">Current {type === 'img' ? 'Image' : 'Video'}:</Typography>
              {type === 'img' ? (
                <img src={`https://aaryanholidays.in//express/uploads/${currentSrc}`} alt="Current" style={{ maxWidth: '200px' }} />
              ) : (
                <video src={`https://aaryanholidays.in//express/uploads/${currentSrc}`} controls style={{ maxWidth: '200px' }} />
              )}
            </Box>
          )}
          {srcPreview && (
            <Box>
              <Typography variant="subtitle1">New {type === 'img' ? 'Image' : 'Video'}:</Typography>
              {type === 'img' ? (
                <img src={srcPreview} alt="Preview" style={{ maxWidth: '200px' }} />
              ) : (
                <video src={srcPreview} controls style={{ maxWidth: '200px' }} />
              )}
            </Box>
          )}
        </Box>
        <TextField
          label="Alt Text"
          fullWidth
          margin="normal"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
          required
        />
        <TextField
          label="Image Order"
          fullWidth
          margin="normal"
          value={imageOrder}
          onChange={(e) => setImageOrder(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>
          Submit
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditGallery;

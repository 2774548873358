import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Table,Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton,Typography } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const ManageDiscovery = () => {
    const [discoveries, setDiscoveries] = useState([]);

    useEffect(() => {
        axios.get('https://aaryanholidays.in/backend/discovery')
            .then(response => setDiscoveries(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this discovery?')) {
            axios.delete(`https://aaryanholidays.in/backend/discovery/${id}`)
                .then(() => setDiscoveries(discoveries.filter(discovery => discovery.id !== id)))
                .catch(error => console.error('Error deleting discovery:', error));
        }
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
        Manage Discovery
      </Typography>
      <Grid container spacing={3}>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="primary" component={Link} to="/discovery/add">
                        Add New Discovery
                    </Button>
                </Grid>
            </Grid>
            
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Discovery</TableCell>
                            <TableCell>Thumbnail Heading</TableCell>
                            <TableCell>Thumbnail Description</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Alt Text</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discoveries.map((discovery) => (
                            <TableRow key={discovery.id}>
                                <TableCell>{discovery.discovery}</TableCell>
                                <TableCell>{discovery.thumbnail_heading}</TableCell>
                                <TableCell>{discovery.thumbnail_desc}</TableCell>
                                <TableCell>
                                    {discovery.image && <img src={`https://aaryanholidays.in//express/uploads/${discovery.image}`} alt={discovery.alt} width="100" />}
                                </TableCell>
                                <TableCell>{discovery.alt}</TableCell>
                                <TableCell>
                                    <IconButton component={Link} to={`/discovery/edit/${discovery.id}`} color="primary">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(discovery.id)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ManageDiscovery;

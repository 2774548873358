import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField, Typography, Input } from '@mui/material';

const AddEditTestimonial = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [subheader, setSubheader] = useState('');
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchTestimonial = async () => {
        try {
          const response = await axios.get(`https://aaryanholidays.in/backend/testimonials/${id}`);
          const data = response.data;
          setTitle(data.title);
          setSubheader(data.subheader);
          setHeading(data.heading);
          setDescription(data.description);
          setCurrentAvatar(data.avatar);
          setIsEditing(true);
        } catch (error) {
          console.error('Error fetching testimonial data', error);
        }
      };
      fetchTestimonial();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subheader', subheader);
    formData.append('heading', heading);
    formData.append('description', description);
    if (avatar) {
      formData.append('avatar', avatar);
    }

    try {
      if (isEditing) {
        await axios.put(`https://aaryanholidays.in/backend/testimonials/${id}`, formData);
      } else {
        await axios.post('https://aaryanholidays.in/backend/testimonials/', formData);
      }
      navigate('/testimonials');
    } catch (error) {
      console.error('Error saving testimonial data', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {isEditing ? 'Edit Testimonial' : 'Add Testimonial'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Subheader"
          value={subheader}
          onChange={(e) => setSubheader(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Heading"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />
        <Input
          type="file"
          onChange={(e) => setAvatar(e.target.files[0])}
          fullWidth
          margin="normal"
        />
        {isEditing && currentAvatar && (
          <Box mt={2}>
            <Typography variant="body2">Current Avatar:</Typography>
            <img
              src={`https://aaryanholidays.in/express/uploads/${currentAvatar}`}
              alt="Current Avatar"
              style={{ width: 100, height: 100, objectFit: 'cover' }}
            />
          </Box>
        )}
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            {isEditing ? 'Update Testimonial' : 'Add Testimonial'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddEditTestimonial;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button,FormControlLabel ,Checkbox,TextField, Typography, Input, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const AddEditSpecialFeature = () => {
  const navigate = useNavigate();
  const { id, tourTypeId } = useParams();
  
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [promote, setPromote] = useState(false);
  const [url, setUrl] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchFeature = async () => {
        try {
          const response = await axios.get(`https://aaryanholidays.in/backend/special-features/${id}`);
          const feature = response.data;
          setType(feature.type);
          setTitle(feature.title);
          setDescription(feature.description);
          setPromote(feature.promote);
          setUrl(feature.website_url);
          if (feature.thumbnail) {
            setImagePreview(`https://aaryanholidays.in/express/uploads/${feature.thumbnail}`);
          }
          setIsEditing(true);
        } catch (error) {
          console.error('There was an error fetching the special feature!', error);
        }
      };

      fetchFeature();
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('type', type);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('tour_type_id', tourTypeId); // Ensure this is included
    formData.append('website_url', url);
    formData.append('promote', promote ? '1' : '0');
    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }

    try {
      if (isEditing) {
        await axios.put(`https://aaryanholidays.in/backend/special-features/${id}`, formData);
      } else {
        await axios.post('https://aaryanholidays.in/backend/special-features', formData);
      }
      navigate(`/manage-special-features/${tourTypeId}`);
    } catch (error) {
      console.error('There was an error saving the special feature!', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {isEditing ? 'Edit Special Feature' : 'Add Special Feature'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Type"
          >
            <MenuItem value="Hotel">Hotel</MenuItem>
            <MenuItem value="Shopping Mall">Shopping Mall</MenuItem>
            <MenuItem value="Kids Arena">Kids Arena</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={promote} onChange={(e) => setPromote(e.target.checked)} />}
          label="Promote to Home Page"
        />
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
        />
        <Box mt={2}>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Thumbnail preview"
              style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
            />
          )}
          <Input
            type="file"
            onChange={(e) => setThumbnail(e.target.files[0])}
            fullWidth
            margin="normal"
          />
        </Box>
        <TextField
          label="URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary">
            {isEditing ? 'Update Special Feature' : 'Add Special Feature'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddEditSpecialFeature;

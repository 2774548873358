import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';

const AddItinerary = () => {
  const { destinations_id } = useParams();
  const [tabHeading, setTabHeading] = useState('');
  const [descriptionHeading, setDescriptionHeading] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('destinations_id', destinations_id);
    formData.append('tab_heading', tabHeading);
    formData.append('description_heading', descriptionHeading);
    formData.append('description', description);
    formData.append('image', image);

    axios.post('https://aaryanholidays.in/backend/itineraries', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setSnackbarMessage('Itinerary added successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate(`/manage-destination/itineraries/${destinations_id}`);
      })
      .catch(error => {
        setSnackbarMessage('There was an error adding the itinerary.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('There was an error adding the itinerary!', error);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Add Itinerary</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Tab Heading"
          fullWidth
          margin="normal"
          value={tabHeading}
          onChange={(e) => setTabHeading(e.target.value)}
          required
        />
        <TextField
          label="Description Heading"
          fullWidth
          margin="normal"
          value={descriptionHeading}
          onChange={(e) => setDescriptionHeading(e.target.value)}
          required
        />
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span" sx={{ mt: 2 }}>
            Choose File
          </Button>
        </label>
        {imagePreview && (
          <Box mt={2}>
            <Typography variant="subtitle1">Image Preview:</Typography>
            <img src={imagePreview} alt="Preview" style={{ maxWidth: '200px' }} />
          </Box>
        )}
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>
          Submit
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddItinerary;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Typography, TextField, InputAdornment, Grid } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material';

const ManageLocations = () => {
    const [locations, setLocations] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get('https://aaryanholidays.in/backend/locations')
            .then(response => setLocations(response.data))
            .catch(error => console.error('Error fetching locations:', error));
    }, []);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this location?')) {
            axios.delete(`https://aaryanholidays.in/backend/locations/${id}`)
                .then(() => setLocations(locations.filter(location => location.id !== id)))
                .catch(error => console.error('Error deleting location:', error));
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredLocations = locations.filter(location => 
        location.location.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Paper style={{ padding: '20px' }}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Manage Locations
                    </Typography>
                </Grid>
                
                <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                    <Button component={Link} to="/add-location" variant="contained" color="primary">
                        Add New Location
                    </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredLocations.map(location => (
                            <TableRow key={location.id}>
                                <TableCell>{location.id}</TableCell>
                                <TableCell>{location.location}</TableCell>
                                <TableCell>
                                    <IconButton component={Link} to={`/edit-location/${location.id}`}><EditIcon /></IconButton>
                                    <IconButton onClick={() => handleDelete(location.id)}><DeleteIcon /></IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ManageLocations;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Grid, Paper } from '@mui/material';

const AddEditDiscovery = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [discovery, setDiscovery] = useState({
        discovery: '',
        thumbnail_heading: '',
        thumbnail_desc: '',
        image: '',
        alt: ''
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        if (id) {
            axios.get(`https://aaryanholidays.in/backend/discovery/${id}`)
                .then(response => setDiscovery(response.data))
                .catch(error => console.error('Error fetching data:', error));
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDiscovery({
            ...discovery,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        // Create a preview of the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('discovery', discovery.discovery);
        formData.append('thumbnail_heading', discovery.thumbnail_heading);
        formData.append('thumbnail_desc', discovery.thumbnail_desc);
        if (selectedImage) formData.append('image', selectedImage);
        formData.append('alt', discovery.alt);

        const request = id ? axios.put(`https://aaryanholidays.in/backend/discovery/${id}`, formData) : axios.post('https://aaryanholidays.in/backend/discovery', formData);

        request
            .then(() => navigate('/manage-discovery'))
            .catch(error => console.error('Error saving data:', error));
    };

    return (
        <Paper style={{ padding: '20px' }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Discovery"
                            name="discovery"
                            value={discovery.discovery}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Thumbnail Heading"
                            name="thumbnail_heading"
                            value={discovery.thumbnail_heading}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Thumbnail Description"
                            name="thumbnail_desc"
                            value={discovery.thumbnail_desc}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            component="label"
                        >
                            Upload Image
                            <input
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </Button>
                        {discovery.image && (
                            <img src={`https://aaryanholidays.in//express/uploads/${discovery.image}`} alt={discovery.alt} width="100" />
                        )}
                        {imagePreview && (
                            <img src={imagePreview} alt="New Upload" width="100" style={{ marginLeft: '20px' }} />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Alt Text"
                            name="alt"
                            value={discovery.alt}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default AddEditDiscovery;

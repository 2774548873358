import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, IconButton, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const ManageCoreCompetency = () => {
  const [competencies, setCompetencies] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompetencies();
  }, []);

  const fetchCompetencies = async () => {
    try {
      const response = await axios.get('https://aaryanholidays.in/backend/competencies');
      setCompetencies(response.data);
    } catch (error) {
      console.error('Error fetching competencies:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this competency?')) {
      try {
        await axios.delete(`https://aaryanholidays.in/backend/competencies/${id}`);
        setSnackbarMessage('Competency deleted successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        fetchCompetencies();
      } catch (error) {
        setSnackbarMessage('There was an error deleting the competency!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('There was an error deleting the competency!', error);
      }
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Manage Core Competency</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to="/add-core-competency"
        >
          Add Core Competency
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Alt Text</TableCell>
              <TableCell>Image Order</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competencies.map((competency) => (
              <TableRow key={competency.id}>
                <TableCell>
                  <img src={`${BASE_MEDIA_URL}${competency.image}`} alt={competency.alt} width="100" />
                </TableCell>
                <TableCell>{competency.alt}</TableCell>
                <TableCell>{competency.image_order}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    component={Link}
                    to={`/edit-core-competency/${competency.id}`}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(competency.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageCoreCompetency;

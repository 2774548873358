import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Typography, Box, Divider } from '@mui/material';
import { Dashboard, Person, Flight, Logout, Menu, Settings } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

const drawerWidth = 240;
const collapsedWidth = 60;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
}));

const Sidebar = ({ open, onClose, onToggle, collapsed }) => {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: open ? drawerWidth : collapsedWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : collapsedWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerHeader>
        {open && (
          <Typography variant="h6" noWrap>
            My Admin Panel
          </Typography>
        )}
        <IconButton onClick={onToggle}>
          <Menu />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon><Dashboard /></ListItemIcon>
          {open && <ListItemText primary="Dashboard" />}
        </ListItem>
        <Divider />
       
        {/* <ListItem button component={Link} to="/manage-locations">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Location" />}
        </ListItem>
        <Divider /> */}
        <ListItem  component={Link} to="/manage-continents">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Manage Tour" />}
        </ListItem>
        <Divider />
        {/* <ListItem button component={Link} to="/manage-special-tour-type">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Special Tour" />}
        </ListItem>
        <Divider /> */}
        <ListItem button component={Link} to="/manage-discovery">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Discovery" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/manage-partners">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Exclusive Partners" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/manage-core-competency">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Core Competency" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/testimonials">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Testimonial" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/home-mantle">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Home Banner" />}
        </ListItem>
        <Divider />
        {/* <ListItem button component={Link} to="/about-mantle">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="About Details" />}
        </ListItem>
        <Divider /> */}
       
        <ListItem button component={Link} to="/manage-accrediation">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Accrediation" />}
        </ListItem>
        <Divider />
        
        <ListItem button component={Link} to="/table/aaryan_contact/Contact/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Contact" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/aaryan_users/User Data/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="User" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/travel_form_submissions/Travel Data/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Travel Data" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/aaryaan_hotel_enquiry/Hotel Enquiry/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Hotel Enquiry" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/aaryaan_newsletter/Newsletter/landscape">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="Newsletter Subscription" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/aaryaan_about/About/potrait">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="About Data" />}
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/table/aaryan_about/About Achievements/potrait">
          <ListItemIcon><Settings /></ListItemIcon>
          {open && <ListItemText primary="About Achievements" />}
        </ListItem>
        <Divider />
        
        
        
        
        <ListItem button onClick={onClose}>
          <ListItemIcon><Logout /></ListItemIcon>
          {open && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;

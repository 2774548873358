import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Grid, Paper, Typography } from '@mui/material';

const AddEditLocation = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [location, setLocation] = useState({
        location: ''
    });

    useEffect(() => {
        if (id) {
            axios.get(`https://aaryanholidays.in/backend/locations/${id}`)
                .then(response => setLocation(response.data))
                .catch(error => console.error('Error fetching location:', error));
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocation({
            ...location,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = id ? axios.put(`https://aaryanholidays.in/backend/locations/${id}`, location) : axios.post('https://aaryanholidays.in/backend/locations', location);

        request
            .then(() => navigate('/manage-locations'))
            .catch(error => console.error('Error saving location:', error));
    };

    return (
        <Paper style={{ padding: '20px' }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {id ? 'Edit Location' : 'Add Location'}
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Location"
                            name="location"
                            value={location.location}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default AddEditLocation;

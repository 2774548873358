import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, IconButton, Tooltip, Breadcrumbs } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const VideoThumbnail = styled('video')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const ManageTourType = () => {
  const { continentId } = useParams();
  const [tourTypes, setTourTypes] = useState([]);
  const [continentName, setContinentName] = useState('');
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchTourTypes = async () => {
      try {
        const response = await axios.get(`https://aaryanholidays.in/backend/continents/tour-types/${continentId}`);
        setTourTypes(response.data);
      } catch (error) {
        console.error('There was an error fetching the tour types!', error);
      }
    };

    const fetchContinentName = async () => {
      try {
        const response = await axios.get(`https://aaryanholidays.in/backend/continents/${continentId}`);
        setContinentName(response.data.continent);
      } catch (error) {
        console.error('There was an error fetching the continent name!', error);
      }
    };

    fetchTourTypes();
    fetchContinentName();
  }, [continentId]);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this tour type?')) {
      axios.delete(`https://aaryanholidays.in/backend/tour-types/${id}`)
        .then(() => setTourTypes(tourTypes.filter(type => type.id !== id)))
        .catch(error => console.error('There was an error deleting the tour type!', error));
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filterTourTypes = (types, searchTerm) => {
    return types.filter(type => {
      return Object.values(type).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link to="/manage-continents" style={{ textDecoration: 'none', color: 'inherit' }}>
          Continents
        </Link>
        <Typography color="text.primary">{continentName}</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom>Manage Tour Types</Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          label="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button component={Link} to={`/manage-tour-type/add/${continentId}`} variant="contained" color="primary">
          Add Tour Type
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Tour Type</StyledTableCell>
            <StyledTableCell>Heading</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Banner</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterTourTypes(tourTypes, search).map((type) => (
            <TableRow key={type.id}>
              <TableCell>{type.id}</TableCell>
              <TableCell>{type.tour_type}</TableCell>
              <TableCell>{type.heading}</TableCell>
              <TableCell>{type.desc_txt.substring(0, 50)}...</TableCell>
              <TableCell>
                {type.banner_type === 'image' ? (
                  <Thumbnail src={`${BASE_MEDIA_URL}${type.banner_src}`} alt={type.banner_alt} />
                ) : (
                  <VideoThumbnail src={`${BASE_MEDIA_URL}${type.banner_src}`} controls />
                )}
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton component={Link} to={`/manage-tour-type/edit/${type.id}/${continentId}`}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(type.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
               
                <Button
                  component={Link}
                  to={`/manage-destination/${type.id}`}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ margin: '4px' }}
                >
                  Manage {type.tour_type} Package
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ManageTourType;

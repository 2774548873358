import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const HomeMantleList = () => {
  const [homeMantles, setHomeMantles] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchHomeMantles = async () => {
      try {
        const response = await axios.get('https://aaryanholidays.in/backend/home-mantle');
        setHomeMantles(response.data);
      } catch (error) {
        console.error('There was an error fetching the home mantles!', error);
      }
    };

    fetchHomeMantles();
  }, []);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this home mantle?')) {
      axios.delete(`https://aaryanholidays.in/backend/home-mantle/${id}`)
        .then(() => setHomeMantles(homeMantles.filter(mantle => mantle.id !== id)))
        .catch(error => console.error('There was an error deleting the home mantle!', error));
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filterHomeMantles = (mantles, searchTerm) => {
    return mantles.filter(mantle => {
      return Object.values(mantle).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link to="/manage-home" style={{ textDecoration: 'none', color: 'inherit' }}>
          Manage Home
        </Link>
        <Typography color="text.primary">Home Mantles</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom>Home Mantles</Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          label="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button component={Link} to="/add-home-mantle" variant="contained" color="primary">
          Add Home Mantle
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Heading</StyledTableCell>
            <StyledTableCell>Sub Heading</StyledTableCell>
            <StyledTableCell>Thumbnail</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterHomeMantles(homeMantles, search).map((mantle) => (
            <TableRow key={mantle.id}>
              <TableCell>{mantle.id}</TableCell>
              <TableCell>{mantle.type}</TableCell>
              <TableCell>{mantle.heading}</TableCell>
              <TableCell>{mantle.sub_heading}</TableCell>
              <TableCell>
                {mantle.type === 'img' ? (
                  <Thumbnail src={`${BASE_MEDIA_URL}${mantle.src}`} alt={mantle.heading} />
                ) : (
                  <video
                    src={`${BASE_MEDIA_URL}${mantle.src}`}
                    controls
                    style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }}
                  />
                )}
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton component={Link} to={`/edit-home-mantle/${mantle.id}`}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(mantle.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default HomeMantleList;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';

const EditItinerary = () => {
  const { id, tourTypeId } = useParams();
  const [destinationsId, setDestinationsId] = useState('');
  const [tabHeading, setTabHeading] = useState('');
  const [descriptionHeading, setDescriptionHeading] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(''); // Holds the existing image
  const [imagePreview, setImagePreview] = useState(null); // Preview for the new image
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the itinerary details based on ID
    axios.get(`https://aaryanholidays.in/backend/itineraries/item/${id}`)
      .then(response => {
        const itinerary = response.data;
        setDestinationsId(itinerary.destinations_id);
        setTabHeading(itinerary.tab_heading);
        setDescriptionHeading(itinerary.description_heading);
        setDescription(itinerary.description);
        setCurrentImage(itinerary.image); // Set the current image
      })
      .catch(error => console.error('There was an error fetching the itinerary!', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('destinations_id', destinationsId);
    formData.append('tab_heading', tabHeading);
    formData.append('description_heading', descriptionHeading);
    formData.append('description', description);

    // Append new image to FormData if it exists, otherwise send the old image
    if (image) {
      formData.append('image', image);
    } else {
      formData.append('currentImage', currentImage);
    }

    // Update itinerary API call
    axios.put(`https://aaryanholidays.in/backend/itineraries/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setSnackbarMessage('Itinerary updated successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate(`/manage-destination/itineraries/${tourTypeId}`);
      })
      .catch(error => {
        setSnackbarMessage('There was an error updating the itinerary.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('There was an error updating the itinerary!', error);
      });
  };

  // Handle new image selection and display the preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file); // Set the new image file
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result); // Show the image preview
    };
    reader.readAsDataURL(file);
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Edit Itinerary</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Tab Heading"
          fullWidth
          margin="normal"
          value={tabHeading}
          onChange={(e) => setTabHeading(e.target.value)}
          required
        />
        <TextField
          label="Description Heading"
          fullWidth
          margin="normal"
          value={descriptionHeading}
          onChange={(e) => setDescriptionHeading(e.target.value)}
          required
        />
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        {/* Hidden file input for image selection */}
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span" sx={{ mt: 2 }}>
            Choose File
          </Button>
        </label>
        <Box display="flex" mt={2}>
          {/* Display current image */}
          {currentImage && (
            <Box mr={2}>
              <Typography variant="subtitle1">Current Image:</Typography>
              <img src={`https://aaryanholidays.in/express/uploads/${currentImage}`} alt="Current" style={{ maxWidth: '200px' }} />
            </Box>
          )}
          {/* Display new image preview */}
          {imagePreview && (
            <Box>
              <Typography variant="subtitle1">New Image:</Typography>
              <img src={imagePreview} alt="Preview" style={{ maxWidth: '200px' }} />
            </Box>
          )}
        </Box>
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Submit
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditItinerary;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Grid, Paper } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'; // Import the icon

const AddEditPartner = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [partner, setPartner] = useState({
        name: '',
        brief: '',
        promotion_order: '',
        video_link: ''
    });

    useEffect(() => {
        if (id) {
            axios.get(`https://aaryanholidays.in/backend/partners/${id}`)
                .then(response => setPartner(response.data))
                .catch(error => console.error('Error fetching partner:', error));
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPartner({
            ...partner,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = id ? axios.put(`https://aaryanholidays.in/backend/partners/${id}`, partner) : axios.post('https://aaryanholidays.in/backend/partners', partner);

        request
            .then(() => navigate('/manage-partners'))
            .catch(error => console.error('Error saving data:', error));
    };

    return (
        <Paper style={{ padding: '20px' }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={partner.name}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Brief"
                            name="brief"
                            value={partner.brief}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Promotion Order"
                            name="promotion_order"
                            value={partner.promotion_order}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Video Link"
                            name="video_link"
                            value={partner.video_link}
                            onChange={handleChange}
                            required
                        />
                        {partner.video_link && (
                            <div style={{ marginTop: '10px' }}>
                                <img src={`https://img.youtube.com/vi/${partner.video_link}/hqdefault.jpg`} alt="Video Thumbnail" width="100" />
                                <a href={`https://www.youtube.com/embed/${partner.video_link}?&controls=1&mute=0&disablekb=1`} target="_blank" rel="noopener noreferrer">
                                    <PlayCircleOutlineIcon />
                                </a>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default AddEditPartner;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField, Typography, RadioGroup, FormControlLabel, Radio, Checkbox, FormControl, FormLabel, Snackbar, Alert } from '@mui/material';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const EditDestination = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [destination, setDestination] = useState({});
  const [locations, setLocations] = useState([]);
  const [discovery, setDiscovery] = useState([]);
  const [flights, setFlights] = useState('');
  const [hotels, setHotels] = useState('');
 
  const [transfers, setTransfers] = useState('');
  const [activities, setActivities] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    axios.get(`https://aaryanholidays.in/backend/destinations/${id}`)
      .then(response => {
        const destinationData = response.data;
        setDestination(destinationData);

        // Extract flights, hotels, transfers, and activities from tour_features
        const features = destinationData.tour_features.split(', ');
        features.forEach(feature => {
          const [count, type] = feature.split(' ');
          if (type === 'Flights') setFlights(count);
          if (type === 'Hotels') setHotels(count);
          if (type === 'Transfers') setTransfers(count);
          if (type === 'Activities') setActivities(count);
        });
      })
      .catch(error => console.error('There was an error fetching the destination!', error));

    axios.get('https://aaryanholidays.in/backend/locations')
      .then(response => setLocations(response.data))
      .catch(error => console.error('There was an error fetching the locations!', error));
      axios.get('https://aaryanholidays.in/backend/discovery')
      .then(response => setDiscovery(response.data))
      .catch(error => console.error('There was an error fetching the locations!', error));
  }, [id]);


  const handleFileChange = (e) => {
    setDestination({ ...destination, src: e.target.files[0] });
  };

  const handleBannerFileChange = (e) => {
    setDestination({ ...destination, banner_src: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


         // Validation: Check if required fields are missing
  if (!destination.thumbnail_heading || !destination.src ) {
    setSnackbarMessage('Please fill out all required fields: Package Name, Thumbnail');
    setSnackbarSeverity('error');
    setOpenSnackbar(true);
    return; // Stop submission if any of the fields are missing
  }
    // Create the tour_features string
    const tourFeatures = `${flights} Flights, ${hotels} Hotels, ${transfers} Transfers, ${activities} Activities`;

    const formData = new FormData();
    formData.append('tour_type_id', destination.tour_type_id);
    formData.append('location', destination.location);
    formData.append('discovery', destination.discovery);
    formData.append('thumbnail_heading', destination.thumbnail_heading);
    formData.append('thumbnail_desc', destination.thumbnail_desc);
    formData.append('type', destination.type);
    if (destination.src instanceof File) {
      formData.append('src', destination.src);
    } else {
      formData.append('src', destination.src); // include existing file path if not changed
    }
    formData.append('alt', destination.alt);
    formData.append('tour_days', destination.tour_days);
    formData.append('tour_features', tourFeatures);
    if (destination.upload_brochures instanceof File) {
      formData.append('upload_brochures', destination.upload_brochures);
    } else {
      formData.append('upload_brochures', destination.upload_brochures); // include existing file path if not changed
    }
    formData.append('body_heading_txt', destination.body_heading_txt);
    formData.append('body_txt', destination.body_txt);
    formData.append('price', destination.price);
    formData.append('destination_order', destination.destination_order);
    formData.append('is_specialized', destination.is_specialized ? 1 : 0);
    formData.append('promote', destination.promote ? 1 : 0);
    formData.append('promote_order', destination.promote_order);
    formData.append('is_active_id', destination.is_active_id ? 1 : 0);
    formData.append('banner_type', 'image');
    if (destination.banner_src instanceof File) {
      formData.append('banner_src', destination.banner_src);
    } else {
      formData.append('banner_src', destination.banner_src); // include existing file path if not changed
    }
    formData.append('banner_alt', destination.banner_alt);
    formData.append('banner_title', destination.banner_title);
    formData.append('banner_description', destination.banner_description);
    formData.append('keyword', destination.keyword); // Add the keyword field to the form data
    formData.append('map', destination.map); // Add the keyword field to the form data
    formData.append('best_time_text', destination.best_time_text); // Add the keyword field to the form data
    axios.put(`https://aaryanholidays.in/backend/destinations/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setSnackbarMessage('Destination updated successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate(`/manage-destination/${destination.tour_type_id}`);
      })
      .catch(error => {
        setSnackbarMessage('There was an error updating the destination!');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('There was an error updating the destination!', error);
      });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Edit Destination</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend" >Best Time To Visit</FormLabel>
          <Box  flexWrap="wrap">
            {locations.map(loc => (
              <FormControlLabel
                key={loc.id}
                control={
                  <Checkbox
                    checked={destination.location?.includes(loc.location)}
                    onChange={(e) => {
                      const newLocation = destination.location ? destination.location.split(', ') : [];
                      if (e.target.checked) {
                        newLocation.push(loc.location);
                      } else {
                        const index = newLocation.indexOf(loc.location);
                        newLocation.splice(index, 1);
                      }
                      setDestination({ ...destination, location: newLocation.join(', ') });
                    }}
                    name={loc.location}
                  />
                }
                label={loc.location}
              />
            ))}
          </Box>
        </FormControl>
        <TextField
          label="Text For Best Time To Visit"
          fullWidth
          margin="normal"
          value={destination.best_time_text || ''}
          onChange={(e) => setDestination({ ...destination, best_time_text: e.target.value })}
        />
        <TextField
          label="Map Link"
          fullWidth
          margin="normal"
          value={destination.map || ''}
          onChange={(e) => setDestination({ ...destination, map: e.target.value })}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend" >discovery Tag</FormLabel>
          <Box  flexWrap="wrap">
            {discovery.map(dis => (
              <FormControlLabel
                key={dis.id}
                control={
                  <Checkbox
                    checked={destination.discovery?.includes(dis.discovery)}
                    onChange={(e) => {
                      const newDiscovery = destination.discovery ? destination.discovery.split(', ') : [];
                      if (e.target.checked) {
                        newDiscovery.push(dis.discovery);
                      } else {
                        const index = newDiscovery.indexOf(dis.discovery);
                        newDiscovery.splice(index, 1);
                      }
                      setDestination({ ...destination, discovery: newDiscovery.join(', ') });
                    }}
                    name={dis.discovery}
                  />
                }
                label={dis.discovery}
              />
            ))}
          </Box>
        </FormControl>
        <TextField
          label="Package Name"
          fullWidth
          margin="normal"
          value={destination.thumbnail_heading || ''}
          onChange={(e) => setDestination({ ...destination, thumbnail_heading: e.target.value })}
        />
        <TextField
          label="Package Description for Thumbnail"
          fullWidth
          margin="normal"
          value={destination.thumbnail_desc || ''}
          onChange={(e) => setDestination({ ...destination, thumbnail_desc: e.target.value })}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Thumbnail Type</FormLabel>
          <RadioGroup row value={destination.type || 'img'} onChange={(e) => setDestination({ ...destination, type: e.target.value })}>
            <FormControlLabel value="img" control={<Radio />} label="Image" />
            <FormControlLabel value="video" control={<Radio />} label="Video" />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Thumbnail Alt"
          fullWidth
          margin="normal"
          value={destination.alt || ''}
          onChange={(e) => setDestination({ ...destination, alt: e.target.value })}
        />
        <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Thumbnail
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {destination.src && (
            typeof destination.src === 'string' ? (
              destination.type === 'img' ? (
                <img src={`${BASE_MEDIA_URL}${destination.src}`} alt="Current Thumbnail" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              ) : (
                <video src={`${BASE_MEDIA_URL}${destination.src}`} controls style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              )
            ) : (
              destination.type === 'img' ? (
                <img src={URL.createObjectURL(destination.src)} alt="New Thumbnail" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              ) : (
                <video src={URL.createObjectURL(destination.src)} controls style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              )
            )
          )}
        </Box>
        
        <TextField
          label="Tour Days"
          fullWidth
          margin="normal"
          value={destination.tour_days || ''}
          onChange={(e) => setDestination({ ...destination, tour_days: e.target.value })}
        />
        <TextField
          label="Banner Alt"
          fullWidth
          margin="normal"
          value={destination.banner_alt || ''}
          onChange={(e) => setDestination({ ...destination, banner_alt: e.target.value })}
        />
        <TextField
          label="Banner Title"
          fullWidth
          margin="normal"
          value={destination.banner_title || ''}
          onChange={(e) => setDestination({ ...destination, banner_title: e.target.value })}
        />
        <TextField
          label="Banner Description"
          fullWidth
          margin="normal"
          multiline
          rows={2}
          value={destination.banner_description || ''}
          onChange={(e) => setDestination({ ...destination, banner_description: e.target.value })}
        />
        {/* <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Banner
            <input type="file" hidden onChange={handleBannerFileChange} />
          </Button>
          {destination.banner_src && (
            typeof destination.banner_src === 'string' ? (
              destination.banner_type === 'image' ? (
                <img src={`${BASE_MEDIA_URL}${destination.banner_src}`} alt="Current Banner" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              ) : (
                <video src={`${BASE_MEDIA_URL}${destination.banner_src}`} controls style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              )
            ) : (
              destination.banner_type === 'image' ? (
                <img src={URL.createObjectURL(destination.banner_src)} alt="New Banner" style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              ) : (
                <video src={URL.createObjectURL(destination.banner_src)} controls style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }} />
              )
            )
          )}
        </Box> */}
         <TextField
            label="Price"
            fullWidth
            margin="normal"
            value={destination.price || ''}
            onChange={(e) => setDestination({ ...destination, price: e.target.value })}
          />
        <Box display="flex" flexDirection="row" justifyContent="space-between" margin="normal">
          <TextField
            label="Flights"
            margin="normal"
            value={flights}
            onChange={(e) => setFlights(e.target.value)}
          />
          <TextField
            label="Hotels"
            margin="normal"
            value={hotels}
            onChange={(e) => setHotels(e.target.value)}
          />
          <TextField
            label="Transfers"
            margin="normal"
            value={transfers}
            onChange={(e) => setTransfers(e.target.value)}
          />
          <TextField
            label="Activities"
            margin="normal"
            value={activities}
            onChange={(e) => setActivities(e.target.value)}
          />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Package Brochure
            <input type="file" hidden onChange={(e) => setDestination({ ...destination, upload_brochures: e.target.files[0] })} />
          </Button>
          {destination.upload_brochures && (
            typeof destination.upload_brochures === 'string' ? (
              <a href={`${BASE_MEDIA_URL}${destination.upload_brochures}`} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px' }}>View Brochure</a>
            ) : (
              <a href={URL.createObjectURL(destination.upload_brochures)} target="_blank" rel="noopener noreferrer" style={{ marginTop: '10px' }}>View Brochure</a>
            )
          )}
        </Box>
        <TextField
          label="Heading"
          fullWidth
          margin="normal"
          value={destination.body_heading_txt || ''}
          onChange={(e) => setDestination({ ...destination, body_heading_txt: e.target.value })}
        />
        <TextField
          label="Detail"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={destination.body_txt || ''}
          onChange={(e) => setDestination({ ...destination, body_txt: e.target.value })}
        />
        
        <TextField
          label="Appear Order"
          fullWidth
          margin="normal"
          value={destination.destination_order || ''}
          onChange={(e) => setDestination({ ...destination, destination_order: e.target.value })}
        />
        
        <FormControlLabel
          control={<Checkbox checked={destination.promote || false} onChange={(e) => setDestination({ ...destination, promote: e.target.checked })} />}
          label="Promote to Home Page"
        />
        <FormControlLabel
          control={<Checkbox checked={destination.is_specialized || false} onChange={(e) => setDestination({ ...destination, is_specialized: e.target.checked })} />}
          label="Marked As specialize Package"
        />
        <TextField
          label="Promote Order"
          fullWidth
          margin="normal"
          value={destination.promote_order || ''}
          onChange={(e) => setDestination({ ...destination, promote_order: e.target.value })}
        />
        <FormControlLabel
          control={<Checkbox checked={destination.is_active_id || false} onChange={(e) => setDestination({ ...destination, is_active_id: e.target.checked })} />}
          label="Active"
        />
        
        
        <TextField
          label="Keywords"
          fullWidth
          margin="normal"
          value={destination.keyword || ''}
          onChange={(e) => setDestination({ ...destination, keyword: e.target.value })}
        />
        
        <Button type="submit" variant="contained" color="primary" fullWidth>Update Destination</Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditDestination;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';
import ManageTourIcon from '@mui/icons-material/Explore';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const ManageContinent = () => {
  const [continents, setContinents] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    axios.get('https://aaryanholidays.in/backend/continents')
      .then(response => setContinents(response.data))
      .catch(error => console.error('Error fetching continents:', error));
  }, []);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this continent?')) {
      axios.delete(`https://aaryanholidays.in/backend/continents/${id}`)
        .then(() => setContinents(continents.filter(continent => continent.id !== id)))
        .catch(error => console.error('Error deleting continent:', error));
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filterContinents = (items, searchTerm) => {
    return items.filter(item => {
      return Object.values(item).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Manage Continents</Typography>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          label="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button component={Link} to="/manage-continents/add" variant="contained" color="primary">
          Add Continent
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Continent</StyledTableCell>
            <StyledTableCell>Heading</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Banner</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterContinents(continents, search).map((continent) => (
            <TableRow key={continent.id}>
              <TableCell>{continent.id}</TableCell>
              <TableCell>{continent.continent}</TableCell>
              <TableCell>{continent.continent_heading}</TableCell>
              <TableCell>{continent.continent_description.substring(0, 50)}...</TableCell>
              <TableCell>
                <Thumbnail src={`${BASE_MEDIA_URL}${continent.continent_banner}`} alt={continent.continent} />
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton component={Link} to={`/manage-continents/edit/${continent.id}`}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(continent.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
               

                <Button
                    component={Link}
                    to={`/manage-tour-type/${continent.id}`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ margin: '4px' }}
                  >
                    Manage {continent.continent} Country 
                  </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ManageContinent;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const TravelList = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get('https://aaryanholidays.in/backend/travel-data');
        setTestimonials(response.data);
      } catch (error) {
        console.error('There was an error fetching the User!', error);
      }
    };
    fetchTestimonials();
  }, []);

  

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Travel Data List
      </Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Destination</TableCell>
              <TableCell>Travellers</TableCell>
              <TableCell>Travel Date</TableCell>
              <TableCell>Trip Duration</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Preference</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonials.map((testimonial) => (
              <TableRow key={testimonial.id}>
                
                <TableCell>{testimonial.destination}</TableCell>
                <TableCell>{testimonial.travellers}</TableCell>
                <TableCell>{testimonial.travel_date}</TableCell>
                <TableCell>{testimonial.trip_duration}</TableCell>
                <TableCell>{testimonial.full_name}</TableCell>
                <TableCell>{testimonial.phone}</TableCell>
                <TableCell>{testimonial.email}</TableCell>
                <TableCell>{testimonial.preferences}</TableCell>
                <TableCell>{testimonial.created_at}</TableCell>
               
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TravelList;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Breadcrumbs,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const VideoThumbnail = styled('video')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const ManageDestination = () => {
  const { tourTypeId } = useParams();
  const [destinations, setDestinations] = useState([]);
  const [search, setSearch] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedDestinationId, setSelectedDestinationId] = useState(null);
  const [continentName, setContinentName] = useState('');
  const [tourTypeName, setTourTypeName] = useState('');

  useEffect(() => {
    // Fetch destinations
    axios
      .get(`https://aaryanholidays.in/backend/destinations?tour_type_id=${tourTypeId}`)
      .then((response) => setDestinations(response.data))
      .catch((error) => console.error('There was an error fetching the destinations!', error));

    // Fetch tour type and continent data
    axios.get(`https://aaryanholidays.in/backend/tour-types/${tourTypeId}`)
      .then(response => {
        setTourTypeName(response.data.tour_type);
        return response.data.continent; // Assuming continent is stored as an ID
      })
      .then(continentId => {
        // Fetch continent name
        axios.get(`https://aaryanholidays.in/backend/continents/${continentId}`)
          .then(response => setContinentName(response.data.continent))
          .catch(error => console.error('Error fetching continent:', error));
      })
      .catch(error => console.error('Error fetching tour type:', error));
  }, [tourTypeId]);

  const handleDelete = (id) => {
    axios
      .delete(`https://aaryanholidays.in/backend/destinations/${id}`)
      .then((response) => setDestinations(destinations.filter((dest) => dest.id !== id)))
      .catch((error) => console.error('There was an error deleting the destination!', error));
    handleCloseConfirmDialog();
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filterDestinations = (dests, searchTerm) => {
    return dests.filter((dest) => {
      return Object.values(dest).some((val) => String(val).toLowerCase().includes(searchTerm.toLowerCase()));
    });
  };

  const handleOpenConfirmDialog = (id) => {
    setSelectedDestinationId(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSelectedDestinationId(null);
    setOpenConfirmDialog(false);
  };

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link to="/manage-continents" style={{ textDecoration: 'none', color: 'inherit' }}>Continents</Link>
        <Link to={`/manage-tour-type/${tourTypeId}`} style={{ textDecoration: 'none', color: 'inherit' }}>{continentName}</Link>
        <Typography color="textPrimary">{tourTypeName}</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>
        Manage Destinations
      </Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField label="Search" value={search} onChange={(e) => handleSearch(e.target.value)} />
        <Button component={Link} to={`/manage-destination/add/${tourTypeId}`} variant="contained" color="primary">
          Add New
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Package Heading</StyledTableCell>
              <StyledTableCell>Package Thumbnail Description</StyledTableCell>
              {/* <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell> */}
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterDestinations(destinations, search).map((dest) => (
              <TableRow key={dest.id}>
                <TableCell>{dest.id}</TableCell>
                <TableCell>{dest.thumbnail_heading}</TableCell>
                <TableCell>{dest.thumbnail_desc.substring(0, 50)}...</TableCell>
                {/* <TableCell>{dest.type}</TableCell>
                <TableCell>
                  {dest.type === 'img' ? (
                    <Thumbnail src={`https://aaryanholidays.in//express/uploads/${dest.src}`} alt={dest.alt} />
                  ) : (
                    <VideoThumbnail src={`https://aaryanholidays.in//express/uploads/${dest.src}`} controls />
                  )}
                </TableCell> */}
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton component={Link} to={`/manage-destination/edit/${dest.id}`}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleOpenConfirmDialog(dest.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                  <Button
                    component={Link}
                    to={`/manage-destination/itineraries/${dest.id}`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ margin: '4px' }}
                  >
                    Manage Itineraries
                  </Button>
                  <Button
                    component={Link}
                    to={`/manage-destination/gallery/${dest.id}`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ margin: '4px' }}
                  >
                    Manage Gallery
                  </Button>
                  <Button
                  component={Link}
                  to={`/manage-special-features/${dest.id}`}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ margin: '4px' }}
                >
                  Manage Special Features
                </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this destination? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(selectedDestinationId)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageDestination;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const TestimonialList = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get('https://aaryanholidays.in/backend/testimonials');
        setTestimonials(response.data);
      } catch (error) {
        console.error('There was an error fetching the testimonials!', error);
      }
    };
    fetchTestimonials();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://aaryanholidays.in/backend/testimonials/${id}`);
      setTestimonials(testimonials.filter((testimonial) => testimonial.id !== id));
    } catch (error) {
      console.error('There was an error deleting the testimonial!', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Testimonial List
      </Typography>
      <Box mb={2}>
        <Link to="/add-testimonial">
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>
            Add Testimonial
          </Button>
        </Link>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Avatar</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Subheader</TableCell>
              <TableCell>Heading</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testimonials.map((testimonial) => (
              <TableRow key={testimonial.id}>
                <TableCell>
                  {testimonial.avatar && (
                    <img
                      src={`https://aaryanholidays.in/express/uploads/${testimonial.avatar}`}
                      alt={testimonial.title}
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                  )}
                </TableCell>
                <TableCell>{testimonial.title}</TableCell>
                <TableCell>{testimonial.subheader}</TableCell>
                <TableCell>{testimonial.heading}</TableCell>
                <TableCell>{testimonial.description}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    component={Link}
                    to={`/edit-testimonial/${testimonial.id}`}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(testimonial.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TestimonialList;

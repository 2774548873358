import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Breadcrumbs,
} from '@mui/material';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const AddEditTourType = () => {
  const { id, continentId } = useParams(); // Get continentId from the URL
  const [tourType, setTourType] = useState('');
  const [heading, setHeading] = useState('');
  const [descTxt, setDescTxt] = useState('');
  const [bannerType, setBannerType] = useState('image');
  const [bannerSrc, setBannerSrc] = useState(null);
  const [bannerAlt, setBannerAlt] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerDescription, setBannerDescription] = useState('');
  const [currentBannerSrc, setCurrentBannerSrc] = useState('');
  const [continentName, setContinentName] = useState(''); // Store continent name
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch continent name based on continentId
    const fetchContinentName = async () => {
      try {
        const response = await axios.get(`https://aaryanholidays.in/backend/continents/${continentId}`);
        setContinentName(response.data.continent);
      } catch (error) {
        console.error('Error fetching continent name:', error);
      }
    };

    fetchContinentName();

    if (id) {
      axios.get(`https://aaryanholidays.in/backend/tour-types/${id}`)
        .then(response => {
          const data = response.data;
          setTourType(data.tour_type);
          setHeading(data.heading);
          setDescTxt(data.desc_txt);
          setBannerType(data.banner_type);
          setCurrentBannerSrc(data.banner_src);
          setBannerAlt(data.banner_alt);
          setBannerTitle(data.banner_title);
          setBannerDescription(data.banner_description);
        })
        .catch(error => console.error('Error fetching the tour type:', error));
    }
  }, [id, continentId]);

  const handleFileChange = (e) => {
    setBannerSrc(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('tour_type', tourType);
    formData.append('heading', heading);
    formData.append('desc_txt', descTxt);
    formData.append('banner_type', bannerType);
    formData.append('banner_src', bannerSrc);
    formData.append('banner_alt', bannerAlt);
    formData.append('banner_title', bannerTitle);
    formData.append('banner_description', bannerDescription);
    formData.append('continent', continentId); // Include continentId in form data

    try {
      if (id) {
        await axios.put(`https://aaryanholidays.in/backend/tour-types/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://aaryanholidays.in/backend/tour-types', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      navigate(`/manage-tour-type/${continentId}`);
    } catch (error) {
      console.error('There was an error saving the tour type!', error);
    }
  };

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/manage-continents">Continents</Link>
        <Link to={`/manage-tour-type/${continentId}`}>{continentName}</Link>
        <Typography color="textPrimary">{id ? 'Edit Country' : 'Add Country'}</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>{id ? 'Edit Country' : 'Add Country'}</Typography>
      <form onSubmit={handleSubmit}>
        
        <TextField
          label="Country Name"
          fullWidth
          margin="normal"
          value={tourType}
          onChange={(e) => setTourType(e.target.value)}
        />
        <TextField
          label="Heading"
          fullWidth
          margin="normal"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
        />
        <TextField
          label="Description"
          fullWidth
          margin="normal"
          value={descTxt}
          onChange={(e) => setDescTxt(e.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Banner Type</FormLabel>
          <RadioGroup row value={bannerType} onChange={(e) => setBannerType(e.target.value)}>
            <FormControlLabel value="image" control={<Radio />} label="Image" />
            <FormControlLabel value="video" control={<Radio />} label="Video" />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel>Upload Banner</FormLabel>
          <input
            type="file"
            onChange={handleFileChange}
            accept={bannerType === 'image' ? 'image/*' : 'video/*'}
          />
          {currentBannerSrc && bannerType === 'image' && (
            <img
              src={`${BASE_MEDIA_URL}${currentBannerSrc}`}
              alt="Current Banner"
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {currentBannerSrc && bannerType === 'video' && (
            <video
              src={`${BASE_MEDIA_URL}${currentBannerSrc}`}
              controls
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {bannerSrc && bannerType === 'image' && (
            <img
              src={URL.createObjectURL(bannerSrc)}
              alt="New Banner"
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {bannerSrc && bannerType === 'video' && (
            <video
              src={URL.createObjectURL(bannerSrc)}
              controls
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
        </FormControl>
        <TextField
          label="Banner Alt"
          fullWidth
          margin="normal"
          value={bannerAlt}
          onChange={(e) => setBannerAlt(e.target.value)}
        />
        <TextField
          label="Banner Title"
          fullWidth
          margin="normal"
          value={bannerTitle}
          onChange={(e) => setBannerTitle(e.target.value)}
        />
        <TextField
          label="Banner Description"
          fullWidth
          margin="normal"
          value={bannerDescription}
          onChange={(e) => setBannerDescription(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">Save</Button>
      </form>
    </Box>
  );
};

export default AddEditTourType;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const VideoThumbnail = styled('video')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const ManageSpecialTourType = () => {
  const [tourTypes, setTourTypes] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    axios.get('https://aaryanholidays.in/backend/special-tour-types')
      .then(response => setTourTypes(response.data))
      .catch(error => console.error('There was an error fetching the tour types!', error));
  }, []);

  const handleDelete = (id) => {
    axios.delete(`https://aaryanholidays.in/backend/tour-types/${id}`)
      .then(response => setTourTypes(tourTypes.filter(type => type.id !== id)))
      .catch(error => console.error('There was an error deleting the tour type!', error));
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filterTourTypes = (types, searchTerm) => {
    return types.filter(type => {
      return Object.values(type).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Manage Special Tour Location</Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          label="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button component={Link} to="/manage-special-tour-type/add" variant="contained" color="primary">
          Add Special Tour Type
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Tour Type</StyledTableCell>
           
            <StyledTableCell>Heading</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Banner</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterTourTypes(tourTypes, search).map((type) => (
            <TableRow key={type.id}>
              <TableCell>{type.id}</TableCell>
              <TableCell>{type.tour_type}</TableCell>
              
              <TableCell>{type.heading}</TableCell>
              <TableCell>{type.desc_txt.substring(0, 50)}...</TableCell>
              <TableCell>
                {type.banner_type === 'image' ? (
                  <Thumbnail src={`${BASE_MEDIA_URL}${type.banner_src}`} alt={type.banner_alt} />
                ) : (
                  <VideoThumbnail src={`${BASE_MEDIA_URL}${type.banner_src}`} controls />
                )}
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton component={Link} to={`/manage-special-tour-type/edit/${type.id}`}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(type.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Manage Package">
                  <IconButton component={Link} to={`/manage-destination/${type.id}`}>
                    Manage {type.tour_type} Package                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ManageSpecialTourType;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const SpecialFeatureList = () => {
  const { tourTypeId } = useParams();
  const [specialFeatures, setSpecialFeatures] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchSpecialFeatures = async () => {
      try {
        const response = await axios.get(`https://aaryanholidays.in/backend/special-features?tour_type_id=${tourTypeId}`);
        setSpecialFeatures(response.data);
      } catch (error) {
        console.error('There was an error fetching the special features!', error);
      }
    };

    fetchSpecialFeatures();
  }, [tourTypeId]);

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this special feature?')) {
      axios.delete(`https://aaryanholidays.in/backend/special-features/${id}`)
        .then(() => setSpecialFeatures(specialFeatures.filter(feature => feature.id !== id)))
        .catch(error => console.error('There was an error deleting the special feature!', error));
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filterSpecialFeatures = (features, searchTerm) => {
    return features.filter(feature => {
      return Object.values(feature).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link to={`/manage-tour-type/${tourTypeId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          Manage Tour Type
        </Link>
        <Typography color="text.primary">Special Features</Typography>
      </Breadcrumbs>

      <Typography variant="h4" gutterBottom>Special Features</Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <TextField
          label="Search"
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Button component={Link} to={`/add-special-feature/${tourTypeId}`} variant="contained" color="primary">
          Add Special Feature
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Location</StyledTableCell>
            <StyledTableCell>Thumbnail</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterSpecialFeatures(specialFeatures, search).map((feature) => (
            <TableRow key={feature.id}>
              <TableCell>{feature.id}</TableCell>
              <TableCell>{feature.type}</TableCell>
              <TableCell>{feature.title}</TableCell>
              <TableCell>{feature.description.substring(0, 50)}...</TableCell>
              <TableCell>
                <Thumbnail src={`${BASE_MEDIA_URL}${feature.thumbnail}`} alt={feature.title} />
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton component={Link} to={`/edit-special-feature/${feature.id}/${tourTypeId}`}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(feature.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SpecialFeatureList;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5',
});

const Thumbnail = styled('img')({
  maxWidth: '100px',
  maxHeight: '100px',
  borderRadius: '8px',
});

const ManageItinerary = () => {
  const { destinations_id } = useParams();
  const [itineraries, setItineraries] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedItineraryId, setSelectedItineraryId] = useState(null);

  useEffect(() => {
    axios
      .get(`https://aaryanholidays.in/backend/itineraries/${destinations_id}`)
      .then((response) => setItineraries(response.data))
      .catch((error) => console.error('There was an error fetching the itineraries!', error));
  }, [destinations_id]);

  const handleDelete = (id) => {
    axios
      .delete(`https://aaryanholidays.in/backend/itineraries/${id}`)
      .then((response) => setItineraries(itineraries.filter((itinerary) => itinerary.id !== id)))
      .catch((error) => console.error('There was an error deleting the itinerary!', error));
    handleCloseConfirmDialog();
  };

  const handleOpenConfirmDialog = (id) => {
    setSelectedItineraryId(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSelectedItineraryId(null);
    setOpenConfirmDialog(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Manage Itineraries
      </Typography>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Button component={Link} to={`/manage-itinerary/add/${destinations_id}`} variant="contained" color="primary">
          Add New
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Tab Heading</StyledTableCell>
              <StyledTableCell>Description Heading</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itineraries.map((itinerary) => (
              <TableRow key={itinerary.id}>
                <TableCell>{itinerary.id}</TableCell>
                <TableCell>{itinerary.tab_heading}</TableCell>
                <TableCell>{itinerary.description_heading}</TableCell>
                <TableCell>{itinerary.description.substring(0, 50)}...</TableCell>
                <TableCell>
                  {itinerary.image && (
                    <Thumbnail src={`https://aaryanholidays.in//express/uploads/${itinerary.image}`} alt={itinerary.tab_heading} />
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton component={Link} to={`/manage-itinerary/edit/${itinerary.id}/${destinations_id}`}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleOpenConfirmDialog(itinerary.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this itinerary? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(selectedItineraryId)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageItinerary;

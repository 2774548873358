import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField, Typography, Snackbar, Alert, Paper } from '@mui/material';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const AddEditCoreCompetency = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [competency, setCompetency] = useState({
    image: '',
    alt: '',
    image_order: ''
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (id) {
      axios.get(`https://aaryanholidays.in/backend/competencies/${id}`)
        .then(response => setCompetency(response.data))
        .catch(error => console.error('There was an error fetching the competency!', error));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompetency({
      ...competency,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('alt', competency.alt);
    formData.append('image_order', competency.image_order);
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    try {
      if (id) {
        await axios.put(`https://aaryanholidays.in/backend/competencies/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setSnackbarMessage('Competency updated successfully!');
      } else {
        await axios.post('https://aaryanholidays.in/backend/competencies', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setSnackbarMessage('Competency added successfully!');
      }
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => navigate('/manage-core-competency'), 2000);
    } catch (error) {
      setSnackbarMessage('There was an error saving the competency!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.error('There was an error saving the competency!', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Paper style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>{id ? 'Edit' : 'Add'} Core Competency</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Alt Text"
          fullWidth
          margin="normal"
          name="alt"
          value={competency.alt}
          onChange={handleChange}
          required
        />
        <TextField
          label="Image Order"
          fullWidth
          margin="normal"
          name="image_order"
          value={competency.image_order}
          onChange={handleChange}
          required
        />
        <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleImageChange}
            />
          </Button>
          {competency.image && !selectedImage && (
            <img src={`${BASE_MEDIA_URL}${competency.image}`} alt={competency.alt} width="100" style={{ marginTop: '10px' }} />
          )}
          {selectedImage && (
            <img src={URL.createObjectURL(selectedImage)} alt="Selected" width="100" style={{ marginTop: '10px' }} />
          )}
        </Box>
        <Button variant="contained" color="primary" type="submit" fullWidth>{id ? 'Update' : 'Add'} Core Competency</Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AddEditCoreCompetency;

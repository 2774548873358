import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Alert
} from '@mui/material';

const AddGallery = () => {
  const { destinations_id } = useParams();
  const [type, setType] = useState('img');
  const [src, setSrc] = useState(null);
  const [srcPreview, setSrcPreview] = useState(null);
  const [alt, setAlt] = useState('');
  const [imageOrder, setImageOrder] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('destinations_id', destinations_id);
    formData.append('type', 'img');
    formData.append('src', src);
    formData.append('alt', alt);
    formData.append('image_order', imageOrder);

    axios.post('https://aaryanholidays.in/backend/gallery', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setSnackbarMessage('Gallery item added successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        navigate(`/manage-destination/gallery/${destinations_id}`);
      })
      .catch(error => {
        setSnackbarMessage('There was an error adding the gallery item.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('There was an error adding the gallery item!', error);
      });
  };

  const handleSrcChange = (e) => {
    const file = e.target.files[0];
    setSrc(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setSrcPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Add Gallery Item</Typography>
      <form onSubmit={handleSubmit}>
        {/* <RadioGroup row value={type} onChange={(e) => setType(e.target.value)}>
          <FormControlLabel value="img" control={<Radio />} label="Image" />
          <FormControlLabel value="video" control={<Radio />} label="Video" />
        </RadioGroup> */}
        <input
          accept={type === 'img' ? 'image/*' : 'video/*'}
          style={{ display: 'none' }}
          id="contained-button-file"
          type="file"
          onChange={handleSrcChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span" sx={{ mt: 2 }}>
            Upload {type === 'img' ? 'Image' : 'Video'}
          </Button>
        </label>
        {srcPreview && (
          <Box mt={2}>
            <Typography variant="subtitle1">{type === 'img' ? 'Image' : 'Video'} Preview:</Typography>
            {type === 'img' ? (
              <img src={srcPreview} alt="Preview" style={{ maxWidth: '200px' }} />
            ) : (
              <video src={srcPreview} controls style={{ maxWidth: '200px' }} />
            )}
          </Box>
        )}
        <TextField
          label="Alt Text"
          fullWidth
          margin="normal"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
          required
        />
        <TextField
          label="Image Order"
          fullWidth
          margin="normal"
          value={imageOrder}
          onChange={(e) => setImageOrder(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, ml: 2 }}>
          Submit
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddGallery;

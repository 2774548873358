import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField, Typography, Snackbar, Alert } from '@mui/material';

const BASE_MEDIA_URL = 'https://aaryanholidays.in//express/uploads/';

const AddEditContinent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [continentData, setContinentData] = useState({
    continent: '',
    continent_heading: '',
    continent_description: '',
    continent_banner: ''
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (id) {
      axios.get(`https://aaryanholidays.in/backend/continents/${id}`)
        .then(response => setContinentData(response.data))
        .catch(error => console.error('Error fetching continent data:', error));
    }
  }, [id]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContinentData({
      ...continentData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('continent', continentData.continent);
    formData.append('continent_heading', continentData.continent_heading);
    formData.append('continent_description', continentData.continent_description);
    if (selectedFile) formData.append('continent_banner', selectedFile);

    try {
      const request = id
        ? axios.put(`https://aaryanholidays.in/backend/continents/${id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        : axios.post('https://aaryanholidays.in/backend/continents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

      await request;
      setSnackbarMessage(`Continent ${id ? 'updated' : 'added'} successfully!`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => navigate('/manage-continents'), 2000);
    } catch (error) {
      setSnackbarMessage('There was an error saving the continent!');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.error('There was an error saving the continent!', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>{id ? 'Edit Continent' : 'Add Continent'}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Continent Name"
          name="continent"
          fullWidth
          margin="normal"
          value={continentData.continent}
          onChange={handleChange}
        />
        <TextField
          label="Continent Heading"
          name="continent_heading"
          fullWidth
          margin="normal"
          value={continentData.continent_heading}
          onChange={handleChange}
        />
        <TextField
          label="Continent Description"
          name="continent_description"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={continentData.continent_description}
          onChange={handleChange}
        />
        <Box display="flex" flexDirection="column" alignItems="start" margin="normal">
          <Button variant="contained" component="label">
            Upload Banner
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {continentData.continent_banner && (
            <img
              src={selectedFile ? URL.createObjectURL(selectedFile) : `${BASE_MEDIA_URL}${continentData.continent_banner}`}
              alt="Banner Preview"
              style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }}
            />
          )}
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {id ? 'Update Continent' : 'Add Continent'}
        </Button>
      </form>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddEditContinent;
